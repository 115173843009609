<template>
    <div class="page-content">
        <div class="mdl-grid">
            <header class="mdl-layout__header header-grey">
                <div class="mdl-layout__header-row">
                    <span class="mdl-layout__title">{{ $t('404.msg')}}</span>
                </div>
            </header>
        </div>
    </div>
</template>

<style scope lang="scss">
</style>

<script>
    export default {
        name: "Error404"
    }
</script>
